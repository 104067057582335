<template>
	<div :class="pageInfo.background">
		<ActivityComponent :activity="acitivty" v-for="(acitivty, index) in activities" :key="index"> </ActivityComponent>
	</div>
</template>

<script>
import {getPageInfo, getAllActivity} from "@/service/api"
import ActivityComponent from "@/components/activity/Activity"
import './style.css';

export default {
	components:{
		ActivityComponent
	},
	name: 'Home',
	props: ['category_id'],
	data() {
		return {
			show_follow: false,
			pageInfo: {},
			activities:[],
		}
    },
    mounted(){
      	this.getPageInfo()
      	this.getAllActivity()
    },
  	methods: {
	  	getPageInfo(){
	  		getPageInfo(this.category_id).then(ret => {
	  			let pageInfo = ret.data
	  			document.title = pageInfo.title
	  			if (pageInfo.descrition != '') {
	  				document.querySelector('meta[name="description"]').setAttribute('content', pageInfo.description)
	  			}

	  			document.body.classList.add(pageInfo.background);
	  			this.pageInfo = pageInfo
	  		})
	  	},
	  	getAllActivity(){
	  		getAllActivity(this.category_id).then(ret => {
	  			this.activities = ret.data
	  		})
	  	},
	  	openUrl(activity) {
	  		switch (activity.link_type) {
	  			case 'taobao':
		  			if (!this.isIos) {
	                    if (this.isWechat) {
	                        alert("请在浏览器打开本网页");
	                        return
	                    } else {
	                        url = url.replace("https://", "taobao://");
	                    }
	                } 

	  		}
	  		if (activity.link_type)
            if (activity.url){
                url = activity.url;
                if (!this.isIos) {
                    if (this.isWechat) {
                        alert("请在浏览器打开本网页");
                        return
                    } else {
                        url = url.replace("https://", "taobao://");
                    }
                } 
                window.top.location.href = url;
            }
        }
  	}
}
</script>