import Vue from 'vue'
import VueRouter from 'vue-router'
import ArticleIndex from '@/pages/article/Index.vue'
import PageIndex from '@/pages/page/Index.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/free/:article_id',
    name: 'free',
    component: ArticleIndex,
    meta: {
      title: "免费小样",
      qrcode: "https://images.yangmaoquan.com/qrcode/free.jpg"
    },
    props: true
  },
  {
    path: '/pages/:category_id',
    name: 'pages',
    component: PageIndex,
    meta: {
      title: "免费小样",
      qrcode: "https://images.yangmaoquan.com/qrcode/free.jpg"
    },
    props: true
  }
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
let router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
});

export default router