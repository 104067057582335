<template>
  <div>
    <BannerComponent v-if="component.type == 'Banner'" :data="data"></BannerComponent>
    <ContentComponent v-if="component.type == 'Content'" :data="data"></ContentComponent>
    <UrlListComponent v-if="component.type == 'UrlList'" :data="data"></UrlListComponent>
    <FollowComponent v-if="component.type == 'Follow'" :data="data"></FollowComponent>
  </div>
</template>

<script>
  import BannerComponent from './BannerComponent'
  import ContentComponent from './ContentComponent'
  import UrlListComponent from './UrlListComponent'
  import FollowComponent from './FollowComponent'
  import './component.css';
  export default {
    components: {
      BannerComponent,
      UrlListComponent,
      ContentComponent,
      FollowComponent
    },  
    props: {
      component: {
        type: Object,
        default: function(){
          return {
            type: '',
            title: 'test',
            short_url: ''
          }
        }
      },
      data: {
        type: Object,
        default: function(){
          return {}
        }
      }
    },
    data() {
      return {};
    },
    methods: {
    }
  }
</script>