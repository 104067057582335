import { fetch, BASE_HOST } from './http.js'

export const getArticleInfo = (id) => fetch('/api/article/' + id);
export const getAllActivity = (category_id) => fetch('/api/pages/' + category_id + "/activities");
export const getPageInfo = (category_id) => fetch('/api/pages/' + category_id);

export const jumpArticleUrl = (id, url) => {
	url = BASE_HOST + "/api/redirect/" + id + "/" + url
	window.open(url)
}
