<template>
  <div id="app">
      <div class="main">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>