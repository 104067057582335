<template>
   <ul class="page-url-list">
      <li @click="openUrl(item)" v-for="(item, index) in data.rows" :key="index">
        {{item.name}}
      </li>
    </ul>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: function(){
          return {rows: []}
        }
      }
    },
    methods: {
      openUrl(item) {
        window.open(item.url)
      }
    }
  }
</script>