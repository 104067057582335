<template>
  <div class="component-banner">
    <img class="component-banner" :src="data.url">
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: function(){
          return {}
        }
      }
    }
  }
</script>