<template>
	<div class="page">
		<ArticleComponent :data="component.data" :component="component" v-for="(component, index) in article.components" :key="index"> </ArticleComponent>
	</div>
</template>

<script>
import {getArticleInfo} from "@/service/api"
import ArticleComponent from "@/components/article/Component"
import './style.css';

export default {
	components:{
		ArticleComponent
	},
	name: 'Home',
	props: ['article_id'],
	data() {
		return {
			show_follow: false,
			article: {},
		}
    },
    mounted(){
      	this.getArticleInfo()
    },
  	methods: {
	  	getArticleInfo(){
	  		getArticleInfo(this.article_id).then(article => {
	  			document.title = article.title
	  			if (article.descrition != '') {
	  				document.querySelector('meta[name="description"]').setAttribute('content', article.description)
	  			}
	  			if (article.disable_share == 1){
	  				document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
					    WeixinJSBridge.call('hideOptionMenu');
					});

	  			}
	  			this.article = article
	  		})
	  	}
  	}
}
</script>