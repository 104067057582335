<template>
  <div class="page-content">
    {{data.content}}
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: function(){
          return {}
        }
      }
    }
  }
</script>