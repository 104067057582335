<template>
  <div class="activity_item" @click="openUrl(activity)">
      <div class="activity_image">
          <img v-if="activity.image_info.length" :src="activity.image_info[0].url">
      </div>
      <div class="activity_content">
          <div>{{activity.title}}</div>
          <div class="activity_description">{{activity.description}}</div>
          <div class="activity_time"> 截止：{{activity.end_time.replace(' 00:00:00','')}}</div>
      </div>
  </div>
</template>

<script>
  import {isIos, isWechat} from "@/utils/wechat"
  export default {
    props: {
      activity: {
        type: Object,
        default: function(){
          return {}
        }
      }
    },
    data() {
      return {
        isIos: false,
        isWechat: false,
      }
    },
    mounted(){
      this.isIos = isIos()
      this.isWechat = isWechat()
    },
    methods: {
      openUrl(activity){
        console.info(this.isIos)
        let url = activity.link_url
        switch (activity.link_type) {
          case 'taobao':
            if (!this.isIos) {
              if (this.isWechat) {
                  alert("请在浏览器打开本网页");
                  return
              } else {
                  url = url.replace("https://", "taobao://");
              }
            } else {
              window.top.location.href = url;
            }
            break;
          default:
            window.top.location.href = url;
        }
      }
    }
  }
</script>